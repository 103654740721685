import React from "react";
import styled from "@emotion/styled";

import { AthleteList } from "./AthleteList";

const mobileBreakpoint = "500px";

export const AthleteImages = () => (
  <HeadshotWrapper>
    <AthleteBox>
      <AthleteList />
    </AthleteBox>
  </HeadshotWrapper>
);

const AthleteBox = styled.div`
  width: calc(40 * 44vw);
  height: 80vw;
  text-align: center;

  @media (min-width: ${mobileBreakpoint}) {
    width: 100%;
    height: 100%;
  }
`;

const HeadshotWrapper = styled.div`
  width: 100vw;
  height: 80vw;
  overflow-x: scroll;

  @media (min-width: ${mobileBreakpoint}) {
    overflow-x: hidden;
    height: auto;
  }
`;
