import React from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { AthleteImages } from "../../../components/landing/email/Athletes";
import { HomeWorkouts } from "../../../components/landing/email/HomeWorkouts";
import { IconContainer } from "../../../components/landing/email/IconPoints";
import { PurchaseContainer } from "../../../components/landing/email/PurchaseContainer";
import Layout from "../../../components/layout/layout";

const tabletBreakpoint = "900px";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const EmailSubscribePage = ({ data: { header, ribbon, footer } }) => {
  const { t } = useTranslation();
  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="no-banner"
    >
      <LocalWrapper>
        <MobileTitle>{t("getInGreatShape")}</MobileTitle>
        <HeroImage>
          <div className="padding"></div>
          <img
            className="desktop-img"
            src="https://images.fitplanapp.com/web/landing/email/banner.jpg?tr=w-2200"
          />
          <img
            className="mobile-img"
            src="https://images.fitplanapp.com/web/landing/email/banner-vertical.jpg?tr=w-1100"
          />
        </HeroImage>
        <GradientContainer>
          <BreakDesktop />
          <DesktopTitle>{t("getInGreatShape")}</DesktopTitle>
          <br />
          <Title>{t("get25%Off")}</Title>
          <br />
          <PurchaseContainer />
          <br />
          <MissionStatement>
            {t("discountCodeInEmail")}
            <br />
            {t("forUseAtOur")}{" "}
            <a href="https://store.fitplanapp.com/">{t("onlineStore")}</a>
          </MissionStatement>
          <br />
          <MissionStatement>{t("youWillGetWorkoutRoutines")}</MissionStatement>
          <br />
          <MissionStatement>
            {t("weAreBetterAndStronger")} <b>{t("together")}</b>.
          </MissionStatement>

          <br />
          <br />
          <IconContainer />
          <br />
          <br />
          <br />
          <br />

          <Title>
            {t("tryOneOfOurCommunity")} <br />
            {t("favWorkoutPlans")}
          </Title>
          <br />
          <HomeWorkouts />
          <br />
          <Title>
            {t("clickToFindMore")} <BreakMobile />
            {t("fromOurTrainers")}
          </Title>
          <AthleteImages />
          <BreakDesktop />
          <BreakDesktop />
          <PurchaseContainer />
          <br />
          <br />
          <br />
          <br />
          <br />
        </GradientContainer>
      </LocalWrapper>
    </Layout>
  );
};

const GifImg = styled.img`
  width: 29vw !important;
  max-width: 290px;
  margin-top: 5% !important;
`;

const HeroImage = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
  min-height: 70vh;

  .padding {
    width: 100%;
    padding-bottom: 170%;
  }

  .desktop-img {
    display: none;
    object-position: center;
  }

  .mobile-img {
    object-position: center top;
  }

  @media (min-width: ${tabletBreakpoint}) {
    min-height: 0;

    .padding {
      padding-bottom: 31%;
    }
    .desktop-img {
      display: block;
    }

    .mobile-img {
      display: none;
    }

    height: auto;

    img {
      height: auto;
    }
  }

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const GradientContainer = styled.div`
  box-shadow: inset 0 7px 9px -7px rgba(128, 130, 177, 0.4);
  position: relative;

  @media (min-width: ${tabletBreakpoint}) {
  }
`;

const LocalWrapper = styled.div`
  font-family: Barlow;
  color: #1d222b;
  background: #fff;
  width: 100%;
`;

const BreakMobile = styled.br`
  @media (min-width: ${tabletBreakpoint}) {
    display: none;
  }
`;

const BreakDesktop = styled.br`
  display: none;

  @media (min-width: ${tabletBreakpoint}) {
    display: block;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  width: 100%;
  text-transform: uppercase;

  @media (min-width: ${tabletBreakpoint}) {
    font-weight: 800;
    font-size: 36px;
  }
`;

const MobileTitle = styled(Title)`
  color: #fff;
  background-color: #202122;
  padding: 4px 8px 4px 8px;
  border: none;

  @media (min-width: ${tabletBreakpoint}) {
    padding-top: 10px;
  }
`;

const DesktopTitle = styled(Title)`
  display: none;

  @media (min-width: ${tabletBreakpoint}) {
    display: none;
  }
`;

const MissionStatement = styled.p`
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 5%;

  @media (min-width: ${tabletBreakpoint}) {
    padding: 0;
  }
`;

export default EmailSubscribePage;
