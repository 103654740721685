import * as React from "react";
import styled from "@emotion/styled";

const tabletBreakpoint = "900px";
const mobileBreakpoint = "500px";

export const HomeWorkouts = () => {
  return (
    <HomeWorkoutWrapper>
      <FeaturedBox>
        <a href="https://fitplan.app.link/ZWjobODkf6">
          <img src="https://images.fitplanapp.com/web/landing/social/featured/1.png?tr=w-600" />
        </a>
        <a href="https://fitplan.app.link/YJlML3NHS5">
          <img src="https://images.fitplanapp.com/web/landing/social/featured/2.png?tr=w-600" />
        </a>
        <a href="https://fitplan.app.link/rfkVBDDHS5">
          <img src="https://images.fitplanapp.com/web/landing/social/featured/3.png?tr=w-600" />
        </a>
        <a href="https://fitplan.app.link/8sUk1uWHS5">
          <img src="https://images.fitplanapp.com/web/landing/social/featured/4.png?tr=w-600" />
        </a>
        <a href="https://fitplan.app.link/ktdQra7HS5">
          <img src="https://images.fitplanapp.com/web/landing/social/featured/5.png?tr=w-600" />
        </a>
        <a href="https://fitplan.app.link/A2uLhN2HS5">
          <img src="https://images.fitplanapp.com/web/landing/social/featured/7.png?tr=w-600" />
        </a>
      </FeaturedBox>
    </HomeWorkoutWrapper>
  );
};

const HomeWorkoutWrapper = styled.div`
  width: 100vw;
  overflow-x: scroll;

  @media (min-width: ${mobileBreakpoint}) {
    overflow-x: hidden;
  }
`;

const FeaturedBox = styled.div`
  text-align: center;
  width: calc(6 * 72vw);
  padding-top: 1%;
  margin-left: 14vw;
  a {
    position: relative;
    &:hover {
      z-index: 400 !important;
    }
  }
  img {
    width: 70vw;
    margin: 0 1vw;
    display: inline;

    transition: 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05) translate(0px, -2.5%);
    }
  }
  @media (min-width: ${mobileBreakpoint}) {
    width: 100vw;
    padding-left: 0;
    overflow: hidden;
    margin: 0 auto 0 -4.5vw;
    img {
      width: 20vw;
      margin: 0 -9vw 0 0;
    }
  }
`;
